import { computed } from 'vue'
import { useStore } from 'vuex'

export function useDeviceSize() {
  const store = useStore()

  const isXxs = computed(() => store.state.mobile.isXxs)
  const isXs = computed(() => store.state.mobile.isXs)
  const isSm = computed(() => store.state.mobile.isSm)
  const isMd = computed(() => store.state.mobile.isMd)
  const isLg = computed(() => store.state.mobile.isLg)
  const isXl = computed(() => store.state.mobile.isXl)
  const isMobile = computed(() => store.state.mobile.isMobile)
  const isTablet = computed(() => store.state.mobile.isTablet)

  const isPc = computed(() => {
    return navigator?.userAgent?.match(/Windows/i) || navigator?.userAgent?.match(/Macintosh/i)
  })

  const isAndroid = computed(() => {
    return navigator?.userAgent?.match(/Android/i)
  })

  const isIOS = computed(() => {
    return navigator?.userAgent?.match(/iPhone|iPad|iPod/i)
  })

  return {
    isXxs,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isMobile,
    isTablet,
    isPc,
    isAndroid,
    isIOS,
  }
}
